<template id="qr-generation-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('qr.title') }}</ion-title>
        <ion-buttons class="modal-confirmation-buttons" slot="end">
            <ExtendableChip
              class="cancel-button"
              :color="isModified ? 'danger' : 'dark'"
              :title="((isModified) ? i18n.$t('default_interaction.discard_question') : undefined)"
              :extendOnClick="isModified"
              @extendedClick="closeModal()"
              :enableClosedClick="!(isModified)"
              @closedClick="closeModal()"
              extendToLeft
              button
              textIcon
              :collapseTimeout="5000"
              >
              <template v-slot:permanent>
                <ion-icon :icon="close" :alt="i18n.$t('default_interaction.discard_question')"></ion-icon>
              </template>
            </ExtendableChip>

            <ion-button
              class="save-button"
              @click="save()"
              fill="solid"
              :disabled="!isModified"
              shape="round"
              color="primary" >
              <ion-icon slot="icon-only" :icon="saveOutline"></ion-icon>
            </ion-button>
            <ion-button
              class="download-button"
              @click="download()" 
              fill="solid"
              shape="round"
              color="tertiary" >
              <ion-icon slot="icon-only" :icon="downloadOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <!-- TODO Return the generated codes for every single one to use them in the calling function! -->
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="false">
      <div class="non-scroll-container">
        <ion-label id="title">{{ i18n.$t(`qr.type.${type}`) }}</ion-label>

        <ion-card id="qr-container">
          <ion-card-header>
            <ion-card-title class="preview-title">
              {{ i18n.$t('qr.preview') }}
              <span v-if="currentIndexAttributes.identifierString != null"> - {{ currentIndexAttributes.identifierString }}</span>
              <ion-badge v-if="currentIndexAttributes.isNew">{{i18n.$t('qr.override.new')}}</ion-badge>
            </ion-card-title>
            <ion-item id="custom-code-buttons" lines="none" v-if="currentIndexAttributes.allowCustomQRCode">
              <ion-label position="stacked">{{ i18n.$t('qr.override.title') }}</ion-label>
              <div id="button-container">
                <CodeScannerButton
                  :disabled="currentIndexOverrideID"
                  :fill="(currentIndexOverrideCustomID != null) ? 'solid' : 'outline'"
                  :removeOnNextClick="(currentIndexOverrideCustomID != null)"
                  onlyAllowInternalCodes
                  :onlyAllowInternalType="type"
                  @codeScanned="(code) => setOverrideCustomID(code)"
                  :text="i18n.$t(`qr.override.${(currentIndexOverrideCustomID != null) ? 'remove_existing' : 'override_existing'}`)">
                </CodeScannerButton>
                <ion-button
                  id="random-code-button"
                  :disabled="currentIndexOverrideCustomID != null"
                  v-if="currentIndexAttributes.generateRandomID && !(currentIndexAttributes.isNew)"
                  :fill="(currentIndexOverrideID) ? 'solid' : 'outline'"
                  @click="toggleCurrentIndexOverrideID">
                  <font-awesome-icon class="random-code-icon" :icon="faDice" />
                  {{ i18n.$t(`qr.override.${(currentIndexOverrideID) ? 'remove_existing' : 'override_random'}`) }}
                </ion-button>
              </div>
            </ion-item>
          </ion-card-header>
          <ion-card-content>
            <ion-slides ref="slidesRef" class="qr-preview-slides" :options="slideOpts">
              <ion-slide v-for="(imageIndex) in targetCount" :key="imageIndex">
                <div class="swiper-zoom-target preview-container" :ref="(el) => setPreviewContainer((imageIndex-1), el)">
                </div>
              </ion-slide>
            </ion-slides>

            <div class="preview-controls">
              <ion-button @click="goToPrevious()" fill="clear" :disabled="currentPreviewIndex <= 0">
                <ion-icon slot="icon-only" :icon="chevronBack"></ion-icon>
              </ion-button>
              <span 
                class="page-index">
                {{ currentPreviewIndex + 1 }} / {{ targetCount }}
                </span>
              <ion-button @click="goToNext()" fill="clear" :disabled="(currentPreviewIndex + 1) >= targetCount">
                <ion-icon slot="icon-only" :icon="chevronForward"></ion-icon>
              </ion-button>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card id="settings-container">
          <ion-list>
            <CollapsibleList class="settings-list"
              :showHeader="true"
              :open="false"
              :title="i18n.$t('qr.settings')">

              <ion-item class="text-position" :lines="(textPosition != null) ? 'none' : 'full'">
                <ion-label position="stacked">
                  {{ i18n.$t('qr.text-position.title') }}
                </ion-label>
                <ion-select interface="popover" :interface-options="{cssClass: 'select-text-position-item'}" :placeholder="i18n.$t('default_interaction.select')" v-model="textPosition">
                  <ion-select-option class="empty" :value="undefined">{{ i18n.$t('qr.text-position.no_text') }}</ion-select-option>
                  <ion-select-option value="left">{{ i18n.$t('qr.text-position.left') }}</ion-select-option>
                  <ion-select-option value="right">{{ i18n.$t('qr.text-position.right') }}</ion-select-option>
                  <ion-select-option value="top">{{ i18n.$t('qr.text-position.top') }}</ion-select-option>
                  <ion-select-option value="bottom">{{ i18n.$t('qr.text-position.bottom') }}</ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item class="information-settings" lines="full" v-if="textPosition != null">
                <ion-label position="stacked">
                  {{ i18n.$t('qr.information-settings.title') }}
                </ion-label>
                <div class="selection-container">
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.showTitles"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.information-settings.showTitles') }}</ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.centerX"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.information-settings.centerX') }}</ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.centerY"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.information-settings.centerY') }}</ion-label>
                  </ion-item>
                </div>
              </ion-item>

              <ion-item class="parameter-selection" lines="full" v-if="textPosition != null">
                <ion-label position="stacked">
                  {{ i18n.$t('qr.parameter-selection.title') }}
                </ion-label>
                <div class="selection-container">
                  <ion-item v-for="(param, paramIndex) in joinedDisplayParams" lines="none" :key="paramIndex">
                    <ion-checkbox slot="start" v-model="chosenParams[param.shortKey]"></ion-checkbox>
                    <ion-label>{{ param.displayTitle }}</ion-label>
                  </ion-item>
                </div>
              </ion-item>

              <ion-item class="change-design" lines="full">
                <ion-label position="stacked">
                  {{ i18n.$t('qr.change-design.title') }}
                </ion-label>
                <div class="selection-container">
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.color"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.change-design.color') }}</ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.hideBackground"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.change-design.hideBackground') }}</ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.includeURL"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.change-design.includeURL') }}</ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-checkbox slot="start" v-model="qrSettings.svg"></ion-checkbox>
                    <ion-label>{{ i18n.$t('qr.change-design.svg') }}</ion-label>
                  </ion-item>
                </div>
              </ion-item>
            </CollapsibleList>
          </ion-list>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonIcon, IonSelect, IonSelectOption, IonCheckbox, IonSlides, IonSlide, IonBadge, IonLabel, modalController } from '@ionic/vue';
import { computed, defineComponent, ref, watch } from 'vue';

import CollapsibleList from '@/components/CollapsibleList.vue';

import CodeScannerButton from '@/components/CodeScannerButton.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faDice } from '@fortawesome/free-solid-svg-icons';

import ExtendableChip from '@/components/ExtendableChip.vue';

import { chevronBack, chevronForward, close, saveOutline, downloadOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { useQR } from '@/components/composables/QR';

import { useStore } from 'vuex';

import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';

const QRGenerationModal = defineComponent({
  name: 'QRGenerationModal',
  components: { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonIcon, IonSelect, IonSelectOption, IonCheckbox, IonSlides, IonSlide, IonBadge, IonLabel, FontAwesomeIcon, CodeScannerButton, CollapsibleList, ExtendableChip },
  props: {
    'targetObjects': Array,
    'type': String,
    'color': String
  },
  setup(props) {
    const i18n = useI18n();

    const store = useStore();

    const { getQRData, generateQR } = useQR();

    const closeModal = function(){
      modalController.dismiss();
    }

    const outputContainers = ref({});

    const overrideID = ref({});

    const overrideCustomID = ref({});

    //Seperate reactive array, becaus re-setting outputContainers causes infinite reactive loop. We need reactivity to draw once container is ready!
    const outputContainerIndizes = ref([]);

    const setPreviewContainer = function(index, element) {
      if (element != outputContainers.value[index]) {
        outputContainers.value[index] = element;
        outputContainerIndizes.value = _.compact(_.map(outputContainers.value, (currentElement, currentIndex) => {
          if (currentElement != null) return currentIndex;
        }));
      }
    }

    const targetCount = computed(() => {
      if (props.targetObjects != null) return props.targetObjects.length;
      return 0;
    });

    //Default settings
    const qrSettings = ref({ color: true, hideBackground: true, includeURL: true, showTitles: true, svg: false }); //TODO Make settings inside store! And define defaults!

    const chosenParams = ref({});

    const textPosition = computed({
      get: () => {
        if (qrSettings.value != null && qrSettings.value.textPosition != null && qrSettings.value.textPosition.length > 0) {
          return qrSettings.value.textPosition;
        } else {
          return undefined;
        }
      },
      set: (newValue) => {
        if (newValue != null && newValue.length > 0) {
          qrSettings.value.textPosition = newValue;
        } else {
          qrSettings.value.textPosition = undefined;
        }
      }
    });

    const slidesRef = ref(null);

    const slideOpts = computed(() => {
      return{
        updateOnWindowResize: true,
        centeredSlides: true,
        resizeObserver: true,
        zoom: {
          maxRatio: 3
        }
      };
    });

    const currentPreviewIndex = ref(0);

    const currentIndexAttributes = computed(() => {
      //Return empty object if not found to not trigger errors in template
      return _.get(dataParams.value, [currentPreviewIndex.value], {});
    });

    const currentIndexOverrideID = computed({
      get: () => (overrideID.value[currentPreviewIndex.value] || false),
      set: (shouldOverride) => {
        //Only update on change
        if (overrideID.value[currentPreviewIndex.value] != shouldOverride) {
          //Set for computed to trigger
          overrideID.value = {
            ...overrideID.value,
            [currentPreviewIndex.value]: shouldOverride
          }
        }
      }
    });

    const currentIndexOverrideCustomID = computed({
      get: () => overrideCustomID.value[currentPreviewIndex.value],
      set: (newCustomID) => {
        //Only update on change
        if (overrideCustomID.value[currentPreviewIndex.value] != newCustomID) {
          //Set for computed to trigger
          overrideCustomID.value = {
            ...overrideCustomID.value,
            [currentPreviewIndex.value]: newCustomID
          }
        }
      }
    });

    const isModified = computed(() => {
      if (_.some(dataParams.value, { 'isModified': true })) return true;
      //Should be already covered by isModified - Fallback
      if (_.some(overrideID.value, (override) => (override === true))) return true;
      if (_.some(overrideCustomID.value, (customID) => (customID != null))) return true;
      return false;
    })

    const toggleCurrentIndexOverrideID = function() {
      currentIndexOverrideID.value = !(currentIndexOverrideID.value === true)
    }

    const setOverrideCustomID = function(newCustomID) {
      currentIndexOverrideCustomID.value = _.get(newCustomID, ['processedValue', 'uuid'], null);
    }

    watch(slidesRef, (newSlides) => {
      if (newSlides != null && newSlides.$el != null) newSlides.$el.getSwiper().then((swiper) => {
        if (swiper) {
          swiper.on('activeIndexChange', function () {
            currentPreviewIndex.value = this.activeIndex;
          });
        }
      })
    });

    const goToPrevious = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper) {
          swiper.slidePrev();
        }
      } catch {
        return;
      }
    }

    const goToNext = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper) {
          swiper.slideNext();
        }
      } catch {
        return;
      }
    }

    const dataParams = computed(() => {
      let getters = {
        'animal': store.getters['horses/getNewestHorseIdForPersonalInfoId']
      }

      let overrideStates = overrideID.value;
      let customOverrides = overrideCustomID.value;
      return _.map(props.targetObjects, (targetObject, objectIndex) => {
        let id = _.get(targetObject, 'id');
        let isNew = (id == null);
        let isModified = isNew;

        //Modify some parts before getting the QR data
        switch (props.type) {
          case 'animal':
            if (id != null) id = getters['animal'](id);
            break;
        
          default:
            break;
        }

        if (targetObject.allowCustomQRCode && customOverrides[objectIndex] != null) {
          id = customOverrides[objectIndex];
          isModified = true;
        }

        if (targetObject.allowCustomQRCode && targetObject.generateRandomID && (id == null || overrideStates[objectIndex] === true)) {
          id = uuidv4();
          isModified = true;
        }

        let qrData = getQRData(props.type, targetObject, id);
        qrData.isNew = isNew;
        qrData.isModified = isModified;
        qrData.allowCustomQRCode = targetObject.allowCustomQRCode;
        qrData.generateRandomID = targetObject.generateRandomID;
        qrData.originalObject = targetObject;
        qrData.index = objectIndex;
        return qrData;
      });
    });

    //Take the options for all different params
    const joinedDisplayParams = computed(() => {
      return _.unionBy(..._.map(dataParams.value, 'displayParams'), 'path');
    });

    const qrImages = ref([]);

    const save = function() {
      modalController.dismiss({
        codes: _.map(qrImages.value, (image, imageIndex) => { //Always same order and size as input
          return {
            image,
            params: dataParams.value[imageIndex]
          }
        })
      });
    }

    const download = function(){
      let imagesAndParams = _.map(qrImages.value, (image, imageIndex) => {
        return {
          image,
          params: dataParams.value[imageIndex]
        }
      });

      _.forEach(imagesAndParams, ({image, params}) => {
        if (image != null) {
          image.download(_.get(params, ['identifierString'], 'anirec_QR'), (_.get(qrSettings.value, ['svg']) == true));
        }
      });
    }

    watch([qrImages, outputContainerIndizes], ([newImages]) => {
      let imagesAndContainers = _.map(newImages, (image, imageIndex) => {
        return {
          image,
          container: outputContainers.value[imageIndex]
        }
      });

      _.forEach(imagesAndContainers, ({image, container}) => {
        if (container != null && image != null) {
          //Hide so it does not snap into place and fades in slowly
          container.style = 'opacity: 0;';
          container.replaceChildren();
          image.append(container);
          setTimeout(() => container.style = 'opacity: 1;transition: opacity 0.3s ease-in-out;', 100);
        }
      });
    });

    watch([dataParams, qrSettings, chosenParams, () => props.color], ([newData, newSettings, filterParams, newColor]) => { //TODO Show message when QR could not be created
      qrImages.value = _.map(newData, (data) => {
        return generateQR(data, {...newSettings, filterParams }, newColor);
      });
    }, { immediate: true, deep: true });

    return { 
      i18n,
      closeModal,
      targetCount,
      slidesRef,
      goToPrevious,
      goToNext,
      isModified,
      currentPreviewIndex,
      currentIndexAttributes,
      currentIndexOverrideID,
      currentIndexOverrideCustomID,
      toggleCurrentIndexOverrideID,
      setOverrideCustomID,
      slideOpts,
      setPreviewContainer,
      joinedDisplayParams,
      download,
      save,
      qrImages,
      qrSettings,
      textPosition,
      chosenParams,
      chevronBack,
      chevronForward,
      close,
      saveOutline,
      downloadOutline,
      faDice
    };
  }
});

export async function openQRGenerationModal(component, targetObjects, type, color){
  if (component != null && targetObjects != null && type != null) {
    const modal = await modalController
      .create({
        component,
        cssClass: 'qr-generation-modal',
        componentProps: {
          targetObjects,
          type,
          color
        }
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default QRGenerationModal;
</script>

<style>
.qr-generation-modal {
  --min-height: 90%;
}

.generated-qr-code {
  transition-duration: unset!important;
  transform: unset!important;
}

.generated-qr-code #background {
  fill-opacity: 1!important; /* Always show background no matter if hidden or not */
}

.select-text-position-item .select-interface-option ion-label {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 6px;
  padding-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
  padding-right: 6px;
}

.select-text-position-item .select-interface-option.empty ion-label {
  font-style: italic;
  opacity: 0.8;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.select-text-position-item .select-interface-option {
  height: auto;
  contain: content;
}

.qr-preview-slides .swiper-wrapper {
  flex-shrink: 1;
  min-height: 0;
  height: auto;
}
</style>

<style scoped>
.non-scroll-container {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.items-container > ion-item > ion-label, #qr-container ion-item > ion-label {
  z-index: 20;
  margin-bottom: 10px;
}

ion-item ion-label {
  pointer-events: none;
}

ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ion-card-title {
  font-size: 1.2em;
}

ion-card {
  margin-block: 5px;
}

#title {
  display: block;
  margin-block: 10px;
  color: var(--ion-color-primary-text);
  width: 100%;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
}

#qr-container {
  position: relative;
  flex: 1 1000 50%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
}

#qr-container ion-card-content {
  padding-bottom: 0px;
}

#qr-container ion-card-content, #qr-container .preview-container {
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
}

#settings-container {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

#settings-container ion-list {
  min-height: 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: var(--ion-card-background, #fff);
}

#settings-container ion-list ion-item, #qr-container ion-item {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.settings-list {
  --color: var(--ion-color-primary-text);
  color: var(--color);
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.settings-list :deep(.items-container) {
  min-height: 0;
}

.preview-title {
  color: var(--ion-color-primary-text);
  display: flex;
  flex-flow: row wrap;
  column-gap: 5px;
}

.preview-title span {
  color: var(--ion-color-step-850, #262626);
  font-weight: normal;
}

.selection-container {
  display: flex;
  flex-direction: column;
}

.selection-container ion-label {
  white-space: normal!important;
}

.qr-preview-slides {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
}

.preview-controls {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#custom-code-buttons #button-container {
  max-width: 100%;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

#custom-code-buttons #button-container > * {
  --color: var(--ion-color-primary-text);
  --border-color: var(--ion-color-primary-text);
  flex: 0 1 auto;
  min-width: 0;
}

#custom-code-buttons #button-container > .button-solid {
  --color: var(--ion-color-primary-contrast);
  --background: var(--ion-color-primary);
}

#random-code-button {
  text-transform: none;
  font-size: 1em;
  height: 2.5em;
  --padding-start: 5px;
  --padding-end: 5px;
}

#random-code-button > .random-code-icon {
  font-size: 1.5em;
  margin-inline-end: 10px;
}


.modal-confirmation-buttons > * {
  margin-left: clamp(5px, 1.5vw, 10px);
  margin-right: clamp(5px, 1.5vw, 10px);
}

.modal-confirmation-buttons ion-button {
  height: 32px;
  width: 32px;
  --padding-start: 5px;
  --padding-end: 5px;
  --border-width: 2px;
}

.cancel-button {
  --custom-size: 32px!important;
  margin-block: 0px;
  margin-inline: 10px;
}

.cancel-button ion-icon {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>